import React from 'react';
import { useForm } from 'react-hook-form';
import { Alert, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import './css/RejectOrder.css';
import CustomTextArea from '../controls/CustomTextArea';

const RejectOrderPopup = ({ onHide, show, onDone, id, showLoading }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const onSubmit = value => {
    onDone(value.reason);
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      className="rejectOrderPopup"
      centered
    >
      {/* <Modal.Header closeButton>
        <Modal.Title>Tip Amount</Modal.Title>
      </Modal.Header> */}
      <Modal.Body>
        {/* <h2>Reject Order #{id}</h2> */}
        <CustomTextArea
          name="reason"
          type="textarea"
          placeholder={t('enter-reject-reason')}
          {...register('reason')}
        ></CustomTextArea>
        {errors.reason?.type === 'required' && (
          <Alert variant="danger">{t('enter-reject-reason-alert')}</Alert>
        )}
        <div className="btn-group">
          <button onClick={onHide} className="btn-cancel">
          {t('cancel')}
          </button>
          <button type="submit" onClick={handleSubmit(onSubmit)} className="btn-done">
            {showLoading ? t('rejecting') : t('reject')}
          </button>
        </div>  
      </Modal.Body>
    </Modal>
  );
};

export default RejectOrderPopup;
